import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core'
import { ZxcvbnResult, zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core'

@Component({
  selector: 'app-password-strength',
  standalone: true,
  imports: [],
  templateUrl: './password-strength.component.html',
  styleUrl: './password-strength.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordStrengthComponent implements OnChanges, OnInit {

  @Input() public passwordToCheck: string = ''
  @Output() passwordStrength = new EventEmitter<boolean>()


  bar0: string = ''
  bar1: string = ''
  bar2: string = ''
  bar3: string = ''


  private colors = ['darkred', 'orangered', 'orange', 'yellowgreen', 'green']

  message: string = ''
  warning: string = ''
  messageColor: string = ''

  async loadOptions() {
    const zxcvbnCommonPackage = await import(
      /* webpackChunkName: "zxcvbnCommonPackage" */ '@zxcvbn-ts/language-common'
    )
    const zxcvbnEnPackage = await import(
      /* webpackChunkName: "zxcvbnEnPackage" */ '@zxcvbn-ts/language-en'
    )

    return {
      translations: zxcvbnEnPackage.translations,
      graphs: zxcvbnCommonPackage.adjacencyGraphs,
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEnPackage.dictionary,
      },
    }

  }

  async ngOnInit(): Promise<void> {
    await this.loadOptions()
  }

  async checkStrength(password: string) : Promise<ZxcvbnResult> {
    zxcvbnOptions.setOptions(await this.loadOptions())
    return zxcvbn(password)
  }

  async ngOnChanges(changes: { [propName: string]: SimpleChange }): Promise<void> {
    const password = changes['passwordToCheck'].currentValue

    this.setBarColors(4, '#DDD')

    if (password) {
      const pwdStrength: ZxcvbnResult = await this.checkStrength(password)
      pwdStrength.score === 4 ? this.passwordStrength.emit(true) : this.passwordStrength.emit(false)

      const color = this.getColor(pwdStrength.score)
      this.setBarColors(color.index, color.color)


      switch (pwdStrength.score) {
        case 0:
        case 1:
          this.message = 'Poor'
          break
        case 2:
          this.message = 'Not Good'
          break
        case 3:
          this.message = 'Average'
          break
        case 4:
          this.message = 'Good'
          break
      }
      this.warning = pwdStrength.feedback.warning ?? ''
    } else {
      this.message = ''
    }
  }

  private getColor(strength: number) {

    this.messageColor = this.colors[strength]

    return {
      index: strength + 1,
      color: this.colors[strength],
    }
  }

  private setBarColors(count: number, color: string) {
    for (let n = 0; n < count; n++) {
      (this as any)['bar' + n] = color
    }
  }
}
