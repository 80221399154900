import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class SiteSearchService {

  private searchToShow = new Subject<string>

  searchToShow$ = this.searchToShow.asObservable()

  constructor() { }

  loadSearch(search: string) {
    this.searchToShow.next(search)
  }
}
